.video-wrapper {
  position: relative;
  padding-top: 56.25%; /* Ratio 16:9 */
  height: 0;
  overflow: hidden;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}